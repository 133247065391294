<template>
  <div>
    <v-container fluid>
      <base-material-card
        icon="mdi-clipboard-text"
        title="ข้อมูลการย้าย บุคลากรทางการศึกษาอื่นตามมาตรา 38 ค.(2)"
        class="px-5 py-3"
      >
        <div class="red--text">
          *เมื่อสถานศึกษารายงานข้อมูลและแนบไฟล์เสร็จสิ้นจะไม่สามารถดำเนินการแก้ไขข้อมูลใดๆ
          ได้แล้วกรุณาตรวจสอบข้อมูลให้ถูกต้องการบันทึก
        </div>
        <v-data-table
          color="success"
          :loading="loading"
          :headers="headers"
          :items="transference_personnel_38s"
          :search="search"
        >
          <template v-slot:top>
            <div>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="ค้นหา ระบุคำ หรือ ส่วนข้อความเกี่ยวข้อง"
                single-line
                hide-details
                dense
                filled
                class="mb-2"
              />
            </div>
          </template>

          <template v-slot:item.tp_steps="{ item }">
            <v-chip color="success" v-if="item.tp_steps === '12'">
              บันทึก
            </v-chip>
            <v-chip @click="changeStatus(item.tid_ref)" color="warning" v-else>
              ยังไม่ได้บันทึก :( {{ item.tp_steps }} )
            </v-chip>
          </template>

          <template v-slot:item.tp_date_time="{ item }">
            <div>
              {{
                item.tp_date_time
                  | moment("add", "543 year")
                  | moment("D MMMM YYYY")
              }}
            </div>
          </template>

          <template v-slot:item.tp_documentsLinkfile="{ item }">
            <div v-if="item.tp_steps === '12'">
              <div v-if="item.tp_documentsLinkfile">
                <v-btn
                  @click="viewtp_documentsLinkfile(item.tp_documentsLinkfile)"
                  text
                  rounded
                  color="green"
                  large
                  ><v-icon>mdi-printer</v-icon></v-btn
                >
              </div>
              <div v-else>
                <v-chip
                  color="info"
                  @click="comment_idPosition(item.tp_id_ref)"
                >
                  <v-icon>mdi-cursor-pointer</v-icon>คลิกเพื่อแนบไฟล์
                </v-chip>
              </div>
            </div>
          </template>

          <template v-slot:item.cancelPDF="{ item }">
            <div v-if="item.tp_steps === '12'">
              <div v-if="item.tp_documentsLinkfile">
                <v-chip color="red" dark v-if="item.tp_ccDocumentFile === 'cc'"
                  >รอการยกเลิก</v-chip
                >
                <v-btn
                  v-else
                  color="warning"
                  @click="cancelPDFSubmit(item.tp_id_ref)"
                >
                  <v-icon color="red" class="pr-1">mdi-close</v-icon>
                  ยกเลิกแนบไฟล์</v-btn
                >
              </div>
              <div v-else>
                <v-chip dark color="warning">
                  <v-icon>mdi-information</v-icon> ไม่ได้แนบไฟล์
                </v-chip>
              </div>
            </div>
          </template>
          <v-alert
            slot="no-results"
            :value="true"
            color="error"
            icon="mdi-alert"
            >ไม่พบผลลัพธ์ "{{ search }}" ที่คุณกำลังค้นหา.</v-alert
          >
        </v-data-table>
      </base-material-card>

      <!-- V-model commentDrdialog -->
      <v-layout row justify-center>
        <v-dialog v-model="commentDrdialog" persistent max-width="60%">
          <v-card class="mx-auto pa-6">
            <base-material-card
              color="yellow"
              icon="mdi-clipboard-text"
              title="แนบไฟล์ แบบคำขอเปลี่ยนตำแหน่ง/ย้าย "
              class="px-5 py-3 text_google"
            ></base-material-card>
            <v-card-text>
              <v-form ref="updatecommentform" lazy-validation>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <h3>
                        รหัสอ้างอิง :
                        {{ transference_personnel_38s_id_ref.tp_id_ref }}
                        {{ transference_personnel_38s_id_ref.id_tfp }}
                      </h3>
                      รหัสบัตรประชาชน :
                      {{ transference_personnel_38s_id_ref.tp_id_card }}
                      <h3>
                        ชื่อ-นามสกุล :
                        {{ transference_personnel_38s_id_ref.title_s
                        }}{{ transference_personnel_38s_id_ref.frist_name }}
                        {{ transference_personnel_38s_id_ref.last_name }}
                        <br />
                        สถานศึกษาปัจจุบัน :
                        {{ transference_personnel_38s_id_ref.college_code }}
                        {{ transference_personnel_38s_id_ref.college_name }}
                        <br />
                        เลขที่ตำแหน่งปัจจุบัน :
                        {{ transference_personnel_38s_id_ref.id_position }}
                      </h3>
                      <h4>
                        ย้ายครั้งที่ :
                        {{ transference_personnel_38s_id_ref.tp_time_s }} ปี :
                        {{ transference_personnel_38s_id_ref.tp_year_s }}
                      </h4>
                    </v-col>

                    <v-col cols="12" md="12">
                      <v-file-input
                        v-model="tp_documentsLinkfiles"
                        accept=".pdf"
                        name="tp_documentsLinkfiles"
                        color="deep-purple accent-4"
                        counter
                        label="ไฟล์แบบเสนอย้ายและหลักฐานอื่นๆ .pdf"
                        placeholder="แบบคำร้องขอย้าย"
                        outlined
                        :show-size="1000"
                        :rules="rules"
                      >
                        <template v-slot:selection="{ index, text }">
                          <v-chip
                            v-if="index < 2"
                            color="deep-purple accent-4"
                            dark
                            label
                            small
                          >
                            {{ text }}
                          </v-chip>

                          <span
                            v-else-if="index === 2"
                            class="text-overline grey--text text--darken-3 mx-2"
                          >
                            +{{ files.length - 2 }} File(s)
                          </span>
                        </template>
                      </v-file-input>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text large @click.stop="commentDrdialog = false" rounded>
                <v-icon dark>mdi-close</v-icon>
              </v-btn>
              <v-btn
                large
                color="green"
                @click.stop="updatecommentSubmit()"
                rounded
              >
                <v-icon dark>mdi-content-save</v-icon>&nbsp;บันทึก
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model transference_personnel_38sCollegePDFCCdialog -->
      <v-layout row justify-center>
        <v-dialog
          v-model="transference_personnel_38sCollegePDFCCdialog"
          persistent
          max-width="60%"
        >
          <v-card class="mx-auto pa-6">
            <base-material-card
              color="red"
              icon="mdi-clipboard-text"
              title="ยกเลิกแนบไฟล์ เนื่องจากไฟล์ไม่สมบูรณ์หรือแนบผิด"
              class="px-5 py-3 text_google"
            ></base-material-card>
            <v-card-text>
              <v-form ref="updatecommentform" lazy-validation>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <h3>
                        รหัสอ้างอิง :
                        {{ transference_personnel_38sCollegePDFCC.tp_id_ref }}
                        {{ transference_personnel_38sCollegePDFCC.id_tfp }}
                      </h3>
                      รหัสบัตรประชาชน :
                      {{ transference_personnel_38sCollegePDFCC.tp_id_card }}
                      <h3>
                        ชื่อ-นามสกุล :
                        {{ transference_personnel_38sCollegePDFCC.title_s
                        }}{{
                          transference_personnel_38sCollegePDFCC.frist_name
                        }}
                        {{ transference_personnel_38sCollegePDFCC.last_name }}
                        <br />
                        สถานศึกษาปัจจุบัน :
                        {{
                          transference_personnel_38sCollegePDFCC.college_code
                        }}
                        {{
                          transference_personnel_38sCollegePDFCC.college_name
                        }}
                        <br />
                        เลขที่ตำแหน่งปัจจุบัน :
                        {{ transference_personnel_38sCollegePDFCC.id_position }}
                      </h3>
                      <h4>
                        ย้ายครั้งที่ :
                        {{ transference_personnel_38sCollegePDFCC.tp_time_s }} ปี :
                        {{ transference_personnel_38sCollegePDFCC.tp_year_s }}
                      </h4>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                fab
                text
                large
                @click.stop="
                  transference_personnel_38sCollegePDFCCdialog = false
                "
                rounded
              >
                <v-icon dark>mdi-close</v-icon>
              </v-btn>
              <v-btn
                large
                color="warning"
                @click.stop="cancelPDFCCSubmitConfirm()"
                rounded
              >
                <v-icon dark>mdi-content-save</v-icon
                >&nbsp;ยืนยันขอยกเลิกแนบไฟล์
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model pdftp_documentsLinkfileDialog -->
      <v-layout row justify-center>
        <v-dialog v-model="pdftp_documentsLinkfileDialog" max-width="80%">
          <v-card class="" elevation="2">
            <embed
              :src="'/HRvecfiles/' + pdf_files"
              width="100%"
              height="700px"
            />
          </v-card>
        </v-dialog>
      </v-layout>
    </v-container>

    <v-container fluid>
      <v-snackbar
        v-model="snackbar.show"
        top
        :timeout="snackbar.timeout"
        :color="snackbar.color"
      >
        <v-icon large>{{ snackbar.icon }}</v-icon>
        <v-card-text>
          {{ snackbar.text }}
        </v-card-text>

        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar.show = false"
            >Close</v-btn
          >
        </template>
      </v-snackbar>
    </v-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loading: false,
      ApiKey: "HRvec2021",
      valid: true,
      transference_personnel_38sCollegePDFCCdialog: false,
      times_select: "1",
      tp_documentsLinkfiles: null,

      years_select: "2565",
      snackbar: {
        show: false,
        color: "",
        timeout: 5000,
        icon: "",
        text: ""
      },
      tp_time_s: [1, 2],
      tp_year_s: [2565, 2566, 2567, 2568, 2569, 2570],
      transference_personnel_38s: [],
      edittransference_personnel_38: {},
      search: "",
      pagination: {},
      headers: [
        { text: "สถานะ/ขั้นตอน", align: "center", value: "tp_steps" },
        { text: "รหัสบัตรประชาชน", align: "center", value: "tp_id_card" },
        { text: "ชื่อ-นามสกุล", align: "center", value: "name_personnel" },
        { text: "ครั้งที่", align: "center", value: "tp_time_s" },
        { text: "ปีที่", align: "center", value: "tp_year_s" },
        { text: "วันที่ทำรายการ", align: "center", value: "tp_date_time" },
        {
          text: "แบบคำร้องขอย้าย",
          align: "center",
          value: "tp_documentsLinkfile"
        },
        { text: "ขอยกเลิกรายการ", align: "center", value: "cancelPDF" }
      ],
      rowsperpage: [
        25,
        50,
        100,
        {
          text: "All",
          value: -1
        }
      ],
      transference_personnel_38status: [],
      userstatus: {},
      commentDrdialog: false,
      transference_personnel_38s_id_ref: [],
      regions: [],
      period_college_file: [],
      period_enable: "1",
      updatecomment: {},
      branch_s: [],
      branch_sub_ds_select: [],
      branchs_id_sub: [],
      period_college_file: [],
      period_college_enable_file: "1",
      rules: [
        value => !value || value.size < 5242880 || "ไฟล์ไม่เกิน 5 MB / 5120 KB",
        value => !!value || ""
      ],
      transference_personnel_38sCollegePDFCC: [],
      data_syslog: {},
      linealerts: {},
      pdf_files: [],
      pdftp_documentsLinkfileDialog: false
    };
  },
  async mounted() {
    await this.periodQuery();
    await this.branchQuery();
    await this.timeCheck();
  },
  methods: {
    async timeCheck() {
      let dateNow = new Date();
      let dateStop = new Date(
        this.period_college_file.period_college_stop + " 16:30:00"
      );
      let dateNows = dateNow.getTime();
      let dateStops = dateStop.getTime();
      if (this.period_college_file.period_college_enable_file == "1") {
        this.interval = setInterval(async () => {
          if (dateNows > dateStops) {
            await this.periodUpdate();
            await this.periodQuery();
          }
        }, 3000);
      }
    },

    async periodUpdate() {
      this.period_college_file.ApiKey = this.ApiKey;
      this.period_college_file.period_college_enable = "0";
      this.period_college_file.period_college_enable_file = "0";
      let result = await this.$http.post(
        "period_college.update.php",
        this.period_college_file
      );
    },

    async branchQuery() {
      let result_branch = await this.$http.post("branch.php", {
        ApiKey: this.ApiKey
      });
      this.branch_s = result_branch.data;
    },
    async periodQuery() {
      let result_period_college_file = await this.$http.post(
        "period_college.php",
        {
          ApiKey: this.ApiKey,
          period_college_enable_file: "1",
          period_college_id: "38101"
        }
      );
      this.period_college_file = result_period_college_file.data;

      if (this.period_college_file.period_college_enable_file != "1") {
        Swal.fire({
          icon: "error",
          title: "ปิดระบบ",
          allowOutsideClick: false,
          allowEscapeKey: false
        }).then(async result => {
          if (result.isConfirmed) {
            this.$router.push("/college");
          }
        });
      } else {
        await this.transference_personnel_38QueryAll();
      }
    },
    async viewtp_documentsLinkfile(tp_documentsLinkfile) {
      this.pdf_files = tp_documentsLinkfile;
      this.pdftp_documentsLinkfileDialog = true;
    },

    async cancelPDFCCSubmitConfirm() {
      this.transference_personnel_38sCollegePDFCC.ApiKey = this.ApiKey;
      this.transference_personnel_38sCollegePDFCC.tp_ccDocumentFile = "cc";
      let result = await this.$http.post(
        "transference_personnel_38.update.php",
        this.transference_personnel_38sCollegePDFCC
      );
      let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
      this.linealerts.ApiKey = this.ApiKey;
      this.linealerts.college_name = userSession.user_firstname;
      this.linealerts.details =
        "ย้ายครู : ขอยกเลิกแนบไฟล์" +
        this.transference_personnel_38sCollegePDFCC.title_s +
        this.transference_personnel_38sCollegePDFCC.frist_name +
        " " +
        this.transference_personnel_38sCollegePDFCC.last_name;
      this.linealerts.datetime = this.date_today;
      if (result.data.status == true) {
        let result_line = await this.$http.post(
          "linenotify_hr.php",
          this.linealerts
        );

        Swal.fire({
          icon: "success",
          title: "ดำเนินการบันทึกข้อมูลเรียบร้อย",
          showConfirmButton: false,
          timer: 1500
        });
        this.transference_personnel_38QueryAll();
        let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
        this.data_syslog.ApiKey = this.ApiKey;
        this.data_syslog.user_account = userSession.user_name;
        this.data_syslog.event_log = "cancel";
        this.data_syslog.page_log = "transference_personnel_38";
        this.data_syslog.table_log = "transference_personnel_38";
        this.data_syslog.detail_log = this.transference_personnel_38sCollegePDFCC.id_ref;
        this.data_syslog.tp_date_times = this.date_today_log;
        await this.$http.post("data_syslog.insert.php", this.data_syslog);
      } else {
        Swal.fire({
          icon: "warning",
          title: "ดำเนินการผิดพลาด",
          showConfirmButton: false,
          timer: 1500
        });
      }
      this.transference_personnel_38sCollegePDFCCdialog = false;
    },
    async cancelPDFSubmit(tp_id_ref) {
      let result_con = await this.$http.post("transference_personnel_38.php", {
        ApiKey: this.ApiKey,
        tp_id_ref: tp_id_ref
      });
      this.transference_personnel_38sCollegePDFCC = result_con.data;
      this.transference_personnel_38sCollegePDFCCdialog = true;
    },

    async sweetAlertLoading() {
      Swal.fire({
        title: "Please Wait !",
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading();
        },
        onBeforeOpen: () => {
          Swal.showLoading();
        },
        onAfterClose: () => {
          Swal.hideLoading();
        },
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false
      });
    },

    async branch_sub_d_select() {
      this.loading = true;
      let result = await this.$http
        .post("branch_sub_d.php", {
          ApiKey: this.ApiKey,
          id_main_branch: this.transference_personnel_38s_id_ref.id_branch
        })
        .finally(() => (this.loading = false));
      this.branch_sub_ds_select = result.data;
    },

    async searchTimeYear() {
      this.loading = true;
      let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
      let result = await this.$http
        .post("transference_personnel_38.php", {
          ApiKey: this.ApiKey,
          tp_time_s: this.times_select,
          tp_year_s: this.years_select,
          tp_college_code_now: userSession.user_name
        })
        .finally(() => (this.loading = false));
      this.transference_personnel_38s = result.data;
    },

    async comment_idPosition(tp_id_ref) {
      if (this.period_college_file.period_college_enable_file == "1") {
        let result_con = await this.$http.post(
          "transference_personnel_38.php",
          {
            ApiKey: this.ApiKey,
            tp_id_ref: tp_id_ref
          }
        );
        this.transference_personnel_38s_id_ref = result_con.data;
        this.tp_documentsLinkfiles = null;

        this.commentDrdialog = true;
      } else {
        Swal.fire({
          icon: "warning",
          title: "ขณะนี้ได้ปิดระบบรับรายงานข้อมูล",
          showConfirmButton: false,
          timer: 1500
        });
      }
    },

    async transference_personnel_38QueryAll() {
      this.loading = true;
      let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
      let result = await this.$http
        .post("transference_personnel_38.php", {
          ApiKey: this.ApiKey,
          tp_college_code_now: userSession.user_name,
          tp_time_s: this.period_college_file.period_college_times,
          tp_year_s: this.period_college_file.period_college_year
        })
        .finally(() => (this.loading = false));
      this.transference_personnel_38s = result.data;
      let data = this.transference_personnel_38s;
      let counts = 0;
      data.forEach(value => {
        counts += 1;
      });
      if (counts < 1) {
        Swal.fire({
          icon: "error",
          title: "ไม่ปรากฎผู้เสนอย้ายออก",
          allowOutsideClick: false,
          allowEscapeKey: false
        }).then(async result => {
          if (result.isConfirmed) {
            this.$router.push("/college");
          }
        });
      }
    },

    async updatecommentSubmit() {
      if (this.$refs.updatecommentform.validate()) {
        await this.sweetAlertLoading();
        this.transference_personnel_38s_id_ref.ApiKey = this.ApiKey;

        let result = "";
        let uploaded = null;

        if (this.tp_documentsLinkfiles != "") {
          let formData = new FormData();
          let filename =
            this.transference_personnel_38s_id_ref.tp_id_ref +
            "" +
            this.time_stamp +
            "" +
            "doc.pdf";
          formData.append("ApiKey", this.ApiKey);
          formData.append("file", this.tp_documentsLinkfiles);
          formData.append("filename", "../HRvecfiles/" + filename);

          result = await this.$http.post("uploadfile.php", formData, {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          });

          this.transference_personnel_38s_id_ref.tp_documentsLinkfile = filename;

          uploaded = true;
        } else {
          uploaded = false;
        }

        let result_up = await this.$http.post(
          "transference_personnel_38.update.php",
          this.transference_personnel_38s_id_ref
        );
        console.log(result_up.data);
        if (result.data.status == true || uploaded) {
          Swal.fire({
            icon: "success",
            title: "ดำเนินการบันทึกข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
          this.transference_personnel_38QueryAll();
          let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
          this.data_syslog.ApiKey = this.ApiKey;
          this.data_syslog.user_account = userSession.user_name;
          this.data_syslog.event_log = "update";
          this.data_syslog.page_log = "transference_personnel_38";
          this.data_syslog.table_log = "transference_personnel_38";
          this.data_syslog.detail_log = this.transference_personnel_38s_id_ref.tp_id_ref;
          this.data_syslog.tp_date_times = this.date_today_log;
          await this.$http.post("data_syslog.insert.php", this.data_syslog);
        } else {
          Swal.fire({
            icon: "warning",
            title: "ดำเนินการผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.commentDrdialog = false;
      }
      Swal.close();
    }
  },
  computed: {
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      )
        return 0;

      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    },
    time_stamp() {
      let time = Date.now();
      return time;
    },
    date_today_log() {
      let today = new Date();
      let dd = String(today.getDate()).padStart(2, "0");
      let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      let yyyy = today.getFullYear() + 543;
      let time =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      today = dd + "/" + mm + "/" + yyyy + "/" + time;
      return today;
    }
  }
};
</script>
